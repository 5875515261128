import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';

import Swiper from "react-id-swiper";
// import "react-id-swiper/lib/styles/css/swiper.css";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {filter} from 'lodash';

const News = props => {

    const language_id = 1;
    const news = filter(props.data.allNews.nodes, function(item){ return item.language_id == language_id})[0];


    const FG_BASE_URL = "https://virtulleum.fluxguide.com/fluxguide/";

    const swiperParams = {
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
        },
        loop: true,
        autoplay: {
            delay: 3000
        },
        zoom: {
            maxRatio: 3
        }
    };


    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="News" />

                {news && (
                    <section className="news_section">
                        <div className="news_wrapper main_content_wrapper">
                            <h1>{news.title}</h1>

                            {news.images.length === 1 && (
                                <div className="swiper_image">
                                    <img src={`${FG_BASE_URL}${news.images[0]}`} alt="" /> 
                                </div>
                            )}

                            {news.images.length > 1 && (
                                <Swiper {...swiperParams}>
                                    {news.images.map(
                                        (image, index) => (
                                            <div key={index}>
                                                <img src={`${FG_BASE_URL}${image}`} alt="" /> 

                                            </div>
                                        )
                                    )}
                                </Swiper>
                            )}

                            <div
                                className="news_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: news.description
                                }}
                            ></div>

                        </div>
                    </section>

                )}

        </Layout>
    );
};

export default News


export const query = graphql`
    query NewsEn {
        allNews {
            nodes {
                language_id
                title
                images
                description
            }
        }
    }
`
  